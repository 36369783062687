import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import 'moment-timezone';
import * as moment from 'moment';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {OAuthService} from 'angular-oauth2-oidc';
import {HttpErrorResponse} from '@angular/common/http';
import {map, startWith} from 'rxjs/operators';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FilterSettingsModel, GridModel, SelectionSettingsModel} from '@syncfusion/ej2-grids/src/grid/base/grid-model';
import {GridComponent, parentsUntil, SelectionService} from '@syncfusion/ej2-angular-grids';
import {ExcelExportProperties, PdfExportProperties, QueryCellInfoEventArgs} from '@syncfusion/ej2-grids';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {Router} from '@angular/router';
import {AggregateRowModel} from '@syncfusion/ej2-grids/src/grid/models/models';
import { ConsultaDfe } from 'src/model/ConsultaDfe';
import { FolderDTOResponse } from 'src/model/dto/FolderDTOResponse';
import { AccountPlanDTOResponse } from 'src/model/dto/AccountPlanDTOResponse';
import { ImovelDTO } from 'src/model/dto/ImovelDTO';
import { ContaBancariaDTO } from 'src/model/dto/ContaBancariaDTO';
import { ParticipantDTOResponse } from 'src/model/dto/ParticipantDTOResponse';
import { HistoricDTOResponse } from 'src/model/dto/HistoricDTOResponse';
import { DatePicker } from 'src/util/datePicker';
import { ConsultaPdfXml } from 'src/providers/dfe/consultaPdfXml';
import { AccountProvider } from 'src/providers/account/account';
import { DonwloadLoteDfe } from 'src/providers/dfe/donwloadLoteDfe';
import { ExcelService } from 'src/app/excel.service';
import { DfeMapper } from 'src/model/dto/mapper/dfeMapper';
import { ErrorUtil } from 'src/util/error';
import { DateUtil } from 'src/util/dateUtil';
import { FolderProvider } from 'src/providers/folder/folder';
import { DocumentProvider } from 'src/providers/bookkeeping/document/document';
import { UserProvider } from 'src/providers/user/user';
import { Snackbar } from 'src/util/snackbar';
import { DonwloadLote } from '../../documentosFiscais/donwload-lote/donwload-lote';
import { PropertyProvider } from 'src/providers/company/property/property';
import { ParticipantProvider } from 'src/providers/participant/participant';
import { BankAccountProvider } from 'src/providers/company/bank_account/bank_account';
import { HistoricProvider } from 'src/providers/bookkeeping/historic/historic';
import { ImportarXML } from '../../documentosFiscais/importar-xml/importarXML';
import { DfeProvider } from 'src/providers/dfe/dfe';
import { ConvertObjectPipe } from 'src/util/pipes/ConvertObjectPipe';
import { Pessoa } from 'src/model/Pessoa';
import { XmlViewerComponent } from '../../documentosFiscais/xml-viewer/xml-viewer.component';
import { CadastrarLancamentoDupComponent } from '../../financeiro/cadastrar-lancamento-dup/cadastrar-lancamento-dup.component';
import { CadastroPastasComponent } from '../../cadastros/pastas/cadastro-pastas/cadastro-pastas.component';
import { Duplicatas } from 'src/model/Duplicatas';
import { CadastrarLancamentoComponent } from '../../financeiro/cadastrar-lancamento/cadastrar-lancamento.component';
import { AccessKeyModalComponent } from '../../documentosFiscais/download-codigo-acesso/access-key-modal/access-key-modal.component';
import { EventsModalComponent } from '../../documentosFiscais/events-modal/events-modal.component';
import {ReportsUtil} from "../../../../util/Reports";

@Component({
  selector: 'app-monitor-fiscal',
  templateUrl: './monitor-fiscal.component.html',
  styleUrls: ['./monitor-fiscal.component.scss'],
  providers: [SelectionService,
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})

export class MonitorFiscalComponent implements OnInit {

  @ViewChild('grid', {static: false}) grid: GridComponent;
  @ViewChild('grid2', {static: false}) grid2: GridComponent;

  public ctes: number;
  public dfeTotal: number;
  public nfesnum: number;
  public nfces: number;
  public links = ['Mensal', 'Personalizado'];
  public activeLink = 'Mensal';
  public years: any;
  public year: any;
  public activeMonth: any;
  public months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
  public changeMonth = false;
  public monthly = true;
  // tslint:disable-next-line: no-use-before-declare
  public dateRange: ConsultDate = new ConsultDate();
  public savingToFolder: boolean;
  public removeFolder = false;
  public mover = false;
  public folderMove: FormGroup;
  public docsVisible = true;
  public dfes: ConsultaDfe[];
  public dfesAux: ConsultaDfe[];
  public dfeSelected = [];
  public selectedDocumentsBookkeeping: ConsultaDfe[] = [];
  public filteredFolders: FolderDTOResponse[];
  public pastas: FolderDTOResponse[];
  public enviando = false;
  public consultando = false;
  public erroDoc = false;
  public erroTipo: string;
  public importarXml = new FormGroup({
    xml: new FormControl(null, [Validators.required, this.requiredFileType('xml')])
  });
  public filterSettings: FilterSettingsModel;
  public selectionSettings: SelectionSettingsModel;
  public filter: { type: string };
  public dataSourceMain: any;
  public toppings = new FormControl();
  public keyword: any;
  public aggregates: AggregateRowModel[];
  private categorias: AccountPlanDTOResponse[];
  private imoveis: ImovelDTO[];
  private contas: ContaBancariaDTO[];
  private participantes: ParticipantDTOResponse[];
  private historicos: HistoricDTOResponse[];
  public carregandoEscrituracao: boolean;

  public duplicatesGrid: GridModel;
  public duplicatesGridAux: GridModel;
  public duplicatas: any[] = [];

  constructor(public datepicker: DatePicker,
              private brMasker: BrMaskerIonicServices3,
              public consultaPdfXml: ConsultaPdfXml,
              private accountProvider: AccountProvider,
              public donwloadLoteDfe: DonwloadLoteDfe,
              public reportsUtil: ReportsUtil,
              private excelService: ExcelService,
              // tslint:disable-next-line:variable-name
              private _bottomSheet: MatBottomSheet,
              public dfeMapper: DfeMapper,
              public errorUtil: ErrorUtil,
              public router: Router,
              public fb: FormBuilder,
              // tslint:disable-next-line:variable-name
              private _adapter: DateAdapter<any>,
              public dateUtil: DateUtil,
              public oauthService: OAuthService,
              public folderProvider: FolderProvider,
              private documentProvider: DocumentProvider,
              public modalService: NgbModal,
              private userProvider: UserProvider,
              public snackBar: Snackbar,
              private donwloadLotePdf: DonwloadLote,
              private imovelProvider: PropertyProvider,
              private participantProvider: ParticipantProvider,
              private contaBancariaProvider: BankAccountProvider,
              private historicProvider: HistoricProvider,
              public importarXML: ImportarXML,
              public dfeProvider: DfeProvider,
              public accessKeyModalService: NgbModal,
              public eventsModalService: NgbModal) {
    setTimeout(() => {
      this.clearSearch();
    }, 600);
  }

  ngOnInit() {
    this.defaultConfiguration();
    this.initializeData();
    this.tableConfiguration();
  }

  searchTable() {
    if (this.keyword === '') {
      this.grid.searchSettings.key = '';
    }
    const wordSearch = this.keyword;
    setTimeout(() => {
      if (wordSearch === this.keyword) {
        if (this.keyword) {
          const searchText: string = (document.getElementsByClassName('searchtext')[0] as any).value;
          this.grid.search(searchText);
        }
      } else {
        this.grid.searchSettings.key = '';
      }
    }, 400);
  }

  clearSearch() {
    this.grid.searchSettings.key = '';
  }

  initializeData() {
    this.getFolders();
    if (localStorage.getItem('idEmpresa') == null) {
      this.userProvider.getAllCompanys().then((user: Pessoa[]) => {
        localStorage.setItem('idEmpresa', user[0].id.toString());
        this.initializeDataDfes();
      });
    } else {
      this.initializeDataDfes();
    }
    this.setDuplicates();
  }

  defaultConfiguration() {
    // tslint:disable-next-line: prefer-const
    let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    this.dateRange.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('year').format();
    this.dateRange.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format();
    this.activeMonth = this.months[+moment.tz(workDate, 'America/Sao_Paulo').format('MM') - 1]; // set month
    this.year = +moment.tz(workDate, 'America/Sao_Paulo').format('YYYY'); // set year
    this.years = getYears(+moment().format('YYYY') - 20);  // set years select
    this.formMoveFolder();  // Configuration form folder
  }

  tableConfiguration() {
    this._adapter.setLocale('pt-BR');
    this.filterSettings = {type: 'Menu'};
    this.filter = {type: 'CheckBox'};
    this.selectionSettings = {persistSelection: false, type: 'Multiple', checkboxOnly: true};
    this.aggregates = [{
      columns: [
        {
          type: 'Sum',
          format: 'C2',
          field: 'valorTotal',
          footerTemplate: '<span style="font-size: 11px;">${Sum}</span>'
        }
      ],
    }];
  }

  tabs(link: string) {
    if (link === 'Mensal') {
      this.changeToMonthlyMode(true);
    } else {
      this.changeToMonthlyMode(false);
    }
  }

  formMoveFolder() {
    this.folderMove = this.fb.group({
      pastas: ['', Validators.compose([Validators.required])]
    });

    this.folderMove.controls.pastas.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterFolder(value))
      ).subscribe(ret => {
      this.filteredFolders = ret;
    });
  }

  getFolders() {
    this.folderProvider.getAllFolders().then((pastas: FolderDTOResponse[]) => {
      this.pastas = pastas;
      this.filteredFolders = this.pastas;
    });
  }

  _filterFolder(value: any): FolderDTOResponse[] {
    if (value != null) {
      const name = value.name ? value.name : value;
      return value ? this.pastas.filter(s => s.name.toLowerCase().indexOf(name.toLowerCase()) > -1)
        : this.pastas;
    } else {
      return this.pastas;
    }
  }

  getFolderName(option) {
    if (option.name !== undefined && option != null) {
      return option.name;
    } else {
      return undefined;
    }
  }

  getDateForRequest(firstRequest: boolean): ConsultDate {
    // tslint:disable-next-line: no-use-before-declare
    const consultDate: ConsultDate = new ConsultDate();
    if (this.monthly) {
      if (firstRequest) {
        // tslint:disable-next-line: prefer-const
        let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
        consultDate.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        consultDate.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
      } else {
        const month = this.getActiveMonth(this.activeMonth);
        const date = this.year + '-' + month + '-' + '01';
        consultDate.startDate = moment.tz(date, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        consultDate.endDate = moment.tz(date, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
      }
    } else {
      consultDate.startDate = moment.tz(this.dateRange.startDate, 'America/Sao_Paulo').format('YYYY-MM-DD');
      consultDate.endDate = moment.tz(this.dateRange.endDate, 'America/Sao_Paulo').format('YYYY-MM-DD');
    }
    return consultDate;
  }

  initializeDataDfes() {
    this.getDfes(this.getDateForRequest(true).startDate, this.getDateForRequest(true).endDate);
  }

  getActiveMonth(mes) {
    switch (mes) {
      case 'JAN':
        mes = '01';
        break;
      case 'FEV':
        mes = '02';
        break;
      case 'MAR':
        mes = '03';
        break;
      case 'ABR':
        mes = '04';
        break;
      case 'MAI':
        mes = '05';
        break;
      case 'JUN':
        mes = '06';
        break;
      case 'JUL':
        mes = '07';
        break;
      case 'AGO':
        mes = '08';
        break;
      case 'SET':
        mes = '09';
        break;
      case 'OUT':
        mes = '10';
        break;
      case 'NOV':
        mes = '11';
        break;
      case 'DEZ':
        mes = '12';
        break;
      default:
        mes = '01';
    }
    return mes;
  }

  getDfesByDate() {
    if (this.dfes !== undefined) {
      this.dfesAux = [];
      this.dfeTotal = undefined;
      this.getDfes(this.getDateForRequest(false).startDate, this.getDateForRequest(false).endDate);
    }
  }

  getDfes(startDate, endDate) {
    this.consultando = true;
    this.dfeProvider.getDfeByPeriod(startDate, endDate).then((dfes: []) => {
      if (dfes != null) {
        this.dfes = this.dfeMapper.arrayToClientModel(dfes);

        // Sem filtro de pasta - Retorna todas menos as arquivadas
        // tslint:disable-next-line:only-arrow-functions
        this.dfesAux = this.dfes.filter(function(dfe) {
          return (dfe.pasta == null) || (dfe.pasta.type !== 'UNSEEN');
        });

        // Com filtro de pasta
        // tslint:disable-next-line:triple-equals
        if (this.toppings.value != undefined && this.toppings.value.length > 0) {
          let dfeFolder = [];
          this.toppings.value.forEach((folder) => {
            // tslint:disable-next-line:only-arrow-functions
            const folderDfe = (this.dfes.filter(function(dfe) {
              return (dfe.pasta != null) && (dfe.pasta.id === folder.id);
            }));
            dfeFolder = dfeFolder.concat(folderDfe);
          });
          this.dfesAux = dfeFolder;
        }

        this.dataSourceMain = this.dfesAux;

        this.setDuplicates();
        this.generateIndicators();
      }

      this.consultando = false;
    }).catch((result: HttpErrorResponse) => {
      this.snackBar.openLong('Erro ao carregar documentos fiscais!', 'erro');
      console.error(result);
      this.erroDoc = true;
      this.consultando = false;
      this.erroTipo = this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents');
    });

  }

  setDuplicates() {
    this.duplicatas = [];
    this.getDuplicates().then(() => {
      this.duplicatesGrid = {
        dataSource: this.duplicatas,
        queryString: 'id',
        height: '100%',
        width: '50%',
        // allowPdfExport: false,
        // allowExcelExport: false,
        columns: [
          {field: 'dueDate', headerText: 'Vencimento'},
          {field: 'value', headerText: 'Valor'},
          {field: 'status', headerText: 'Escriturada', disableHtmlEncode: false},
        ],
      };
    });
  }

  async getDuplicates() {
    for (const i in this.dataSourceMain) {
      // tslint:disable-next-line: forin
      for (const j in this.dataSourceMain[i].pagamentoPlanejado
        .sort((x, y) => {
        if (x.vencimento > y.vencimento) {
          return 1;
        }
        if (x.vencimento < y.vencimento) {
          return -1;
        }
        return 0;
      })
      ) {
        this.duplicatas.push({id: this.dataSourceMain[i].id,
                              status: this.dataSourceMain[i].pagamentoPlanejado[j].escriturada ?
                              '<i class="fas fa-book colorido" style="color: #228B22 !important;"></i>' :
                              '<i class="fas fa-exclamation-triangle colorido" style="color: #FF9800 !important;"></i>',
                              number: this.dataSourceMain[i].pagamentoPlanejado[j].numero,
                              value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                                      .format(this.dataSourceMain[i].pagamentoPlanejado[j].valor),
                              dueDate: moment(this.dataSourceMain[i].pagamentoPlanejado[j].vencimento).format('DD/MM/YYYY')});
      }
    }
  }

  generateIndicators() {
    this.ctes = 0;
    this.nfces = 0;
    this.nfesnum = 0;
    this.dfeTotal = this.dfesAux.length;
    this.dfesAux.forEach((dfe: ConsultaDfe) => {
      if (dfe.modelo === 'nfe') {
        this.nfesnum++;
      } else if (dfe.modelo === 'cte') {
        this.ctes++;
      } else if (dfe.modelo === 'nfce') {
        this.nfces++;
      }
    });
  }

  checkEscrituracao(duplicatas): number {
    let quantidadeEscriturada = 0;
    duplicatas.forEach((duplicata) => {
      if (duplicata !== undefined) {
        if (duplicata.escriturada) {
          quantidadeEscriturada++;
        }
      }
    });
    return quantidadeEscriturada;
  }

  donwloadLote(type: string) {
      if (this.selectedDocumentsBookkeeping.length === 0) {
        this.snackBar.openLong('Nenhum documento selecionado! Selecione ao menos um!', 'erro');
      } else {
        this.snackBar.openLong('O arquivo em lote está sendo gerado...', 'atencao');
        this.getFileDfeLote(this.selectedDocumentsBookkeeping, type);
      }
  }

  getFileDfeLote(documents, type) {
    this.donwloadLotePdf.getFileDfeLote(documents, type).then(() => {
      this.snackBar.openLong('Arquivo ' + type.toUpperCase() + ' gerado com sucesso!', 'success');
    }).catch(() => {
      this.snackBar.openLong('Não foi possível gerar o arquivo em lote.', 'erro');
    });
  }

  getFileDfe(id, type, location) {
    this.carregandoEscrituracao = true;
    this.dfeProvider.getDfeByID(id).then((dfe: any) => {
      const document = {
        xml: atob(dfe.dfeBytes),
        modelo: dfe.modelType,
        chave: dfe.key
      };
      switch (type) {
        case 'xml': {
          const format = require('xml-formatter');
          const xml = document.xml;
          const formattedXml = format(xml, {
            indentation: '  ',
            filter: (node) => node.type !== 'Comment',
            collapseContent: true,
            lineSeparator: '\n'
          });
          const modalOptions: NgbModalOptions = {};
          modalOptions.backdrop = 'static';
          // modalOptions.keyboard = false;
          const modalRef = this.modalService.open(XmlViewerComponent, modalOptions);
          modalRef.componentInstance.xml = formattedXml;
          modalRef.componentInstance.documento = document;
          this.carregandoEscrituracao = false;
          break;
        }
        case 'pdf': {
          switch (location) {
            case 'modal':
              this.consultaPdfXml.getPdfModal(document).then((result) => {
                this.carregandoEscrituracao = false;
              }).catch((result: HttpErrorResponse) => {
                this.carregandoEscrituracao = false;
                console.error(result);
                this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
                this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
              });
              break;
            case 'blank':
              this.consultaPdfXml.getPdf(document).then((result) => {
                this.carregandoEscrituracao = false;
              }).catch((result: HttpErrorResponse) => {
                this.carregandoEscrituracao = false;
                console.error(result);
                this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
                this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
              });
              break;
          }
          break;
        }
      }
    }).catch((result: HttpErrorResponse) => {
      this.carregandoEscrituracao = false;
      console.error(result);
      this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
    });
  }

  requiredFileType(type: string) {
    // tslint:disable-next-line:only-arrow-functions
    return function(control: FormControl) {
      const file = control.value;
      if (file) {
        const extension = file.split('.')[1].toLowerCase();
        if (type.toLowerCase() !== extension.toLowerCase()) {
          return {
            requiredFileType: true
          };
        }
        return null;
      }
      return null;
    };
  }

  logDataSourceMain() {
   // console.log(this.dataSourceMain);
  }

  setSelectedDocumentsToBookkeeping() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastrarLancamentoDupComponent, modalOptions);
    modalRef.componentInstance.documentosExistentes = this.selectedDocumentsBookkeeping;
    modalRef.componentInstance.categoriasCache = this.categorias;
    modalRef.componentInstance.imoveisCache = this.imoveis;
    modalRef.componentInstance.contasCache = this.contas;
    modalRef.componentInstance.participantesCache = this.participantes;
    modalRef.componentInstance.historicosCache = this.historicos;
    modalRef.result.then(() => {
      this.getDfesByDate();
      this.selectedDocumentsBookkeeping.splice(0, this.selectedDocumentsBookkeeping.length);
    }, (result) => {
      if (result) {
        this.getDfesByDate();
      }
    });
    // tslint:disable-next-line: deprecation
    event.preventDefault();
  }

  setFolderToDoc(folder) {
    this.selectedDocumentsBookkeeping.forEach((dfe: ConsultaDfe) => {
      this.dataSourceMain.forEach((dfeList) => {
        if (dfeList.id === dfe.id) {
          dfeList.pasta = folder;
        }
      });
    });
    this.grid.refreshColumns();
  }

  openModalNewOptionFolder() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPastasComponent, modalOptions);
    modalRef.result.then((result) => {
      this.pastas.push(result);
    }, () => {

    });
  }

  saveToFolder(folder: any) {
    this.savingToFolder = true;
    if (this.folderMove.valid) {
      const dfesToFolder = [];
      this.selectedDocumentsBookkeeping.forEach((dfe: ConsultaDfe) => {
        const objectFolder = {
          newFolderId: folder.id,
          companyId: +localStorage.getItem('idEmpresa'),
          dfeId: dfe.id
        };
        dfesToFolder.push(objectFolder);
      });
      this.dfeProvider.putFolderDfes(dfesToFolder).then(() => {
        this.setFolderToDoc(folder);
        if (folder.type === 'UNSEEN') {
          this.getDfesByDate();
        }
        this.changeMonth = false;
        this.mover = false;
        this.savingToFolder = false;
        this.selectedDocumentsBookkeeping.splice(0, this.selectedDocumentsBookkeeping.length);
        this.snackBar.openLong('Arquivos adicionados a pasta ' + folder.name + ' com sucesso!', 'success');
        this.filteredFolders = this.pastas;
      }).catch((result: HttpErrorResponse) => {
        this.snackBar.openLong('Não foi possível inserir! ' +
          this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'erro');
        this.savingToFolder = false;
      });
    } else {
      this.savingToFolder = false;
      this.snackBar.openLong('Você deve selecionar uma pasta!', 'erro');
    }
  }

  removeFolderLocalData(row) {
    const dfeID = this.dataSourceMain.findIndex(dfe => dfe.id === row.id);
    this.dataSourceMain[dfeID].pasta = null;
  }

  removeFromFolder(row: any) {
    this.removeFolder = true;
    const objectFolder = {
      newFolderId: null,
      companyId: +localStorage.getItem('idEmpresa'),
      dfeId: row.id
    };
    this.dfeProvider.putFolderDfes([objectFolder]).then(() => {
      if (row.pasta.type === 'UNSEEN') {
        this.getDfesByDate();
      }

      row.pasta = null;
      this.removeFolderLocalData(row);
      this.removeFolder = false;

      this.snackBar.openLong('Documento removido da pasta com sucesso!', 'success');
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
      this.removeFolder = false;
      this.snackBar.openLong('Não foi possível remover! ' +
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'erro');
    });
  }

  getBookkeping(pagamentoPlanejado: Duplicatas[]) {
    if (this.checkEscrituracao(pagamentoPlanejado) > 0) {
      let idFound = 0;
      pagamentoPlanejado.forEach((pagamento) => {
        if (pagamento.escriturada && idFound === 0) {
          idFound++;
          this.documentProvider.getDocumentBookkeepingByPayment(pagamento.id).then((bookkeeping: any) => {
            let modalOptions: NgbModalOptions = {
              backdrop: 'static',
              windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
            };
            const modalRef = this.modalService.open(CadastrarLancamentoComponent, modalOptions);
            modalRef.componentInstance.lancamentoExistenteID = bookkeeping.id;
            modalRef.componentInstance.categoriasCache = this.categorias;
            modalRef.componentInstance.imoveisCache = this.imoveis;
            modalRef.componentInstance.contasCache = this.contas;
            modalRef.componentInstance.participantesCache = this.participantes;
            modalRef.componentInstance.historicsCache = this.historicos;
            modalRef.result.then((result) => {
              if (result) {
                this.getDfesByDate();
              }
            }, (result) => {
              if (result) {
                this.getDfesByDate();
              }
            });
          });
        }
      });

    } else {
      this.snackBar.openLong('Não há nenhuma escrituração realizada!', 'atencao');
    }
  }

  getBookkepingRow(rowTable) {
    if (rowTable != null && rowTable.target.classList.contains('e-rowcell')) {
      const row: any = (this.grid.getRowObjectFromUID(parentsUntil(rowTable.target, 'e-row').getAttribute('data-uid')));
      this.getBookkeping(row.data.pagamentoPlanejado);
    } else if (rowTable == null) {
    }
  }

  selectDocumentForBookkeeping(document: ConsultaDfe) {
    if (this.checkIfDfeAlreadySelected(document)) {
      this.selectedDocumentsBookkeeping.push(document);
    } else {
      this.selectedDocumentsBookkeeping.splice(this.selectedDocumentsBookkeeping.findIndex(val => val.id === document.id), 1);
    }
  }

  checkIfDfeAlreadySelected(document): boolean {
    return this.selectedDocumentsBookkeeping.findIndex(val => val.id === document.id) === -1;
  }

  changeYear(type: string) {
    if (type === '+') {
      this.year = +this.year + 1;
    } else {
      this.year = +this.year - 1;
    }
    let existe = 0;
    this.years.forEach((ano: number) => {
      if (this.year === ano) {
        existe++;
      }
    });
    if (existe === 0) {
      this.years.push(this.year);
      this.years.sort(
        // tslint:disable-next-line:only-arrow-functions
        function(b, a) {
          return a < b ? -1 : a > b ? 1 : 0;
        });
    }
    this.getDfesByDate();
  }

  changeToMonthlyMode(b: any) {
    this.monthly = b;
    this.getDfesByDate();
  }

  changePeriod(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      this.getDfesByDate();
    }
  }

  pdfExport() {
    const date = moment().format('DD/MM/YY HH:mm').toString();
    this.duplicatesGridAux = this.duplicatesGrid;
    this.duplicatesGrid = null;
    let period: string;
    if (this.activeLink !== 'Personalizado') {
      period = this.activeMonth + ' - ' + this.year;
    } else {
      period = moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY');
    }

    let header = this.reportsUtil.PDFHeader({
      arquivo: `Documentos fiscais - ${period}`,
      titulo: `Documentos fiscais`,
      data: date,
      periodo: period,
      conteudo: {},
      filhos: false,
      orientacao: 'Landscape'
    });

    for (const cols of this.grid.columns) {
      if ((cols as any).field === 'doc.participant.name') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'pagamentoPlanejado.lenght') {
        (cols as any).width = 60;
      }
      if ((cols as any).field === 'arquivo') {
        (cols as any).visible = false;
      }
      if ((cols as any).field === 'checkbox') {
        (cols as any).visible = false;
      }
      if ((cols as any).field === 'dueDate') {
        (cols as any).visible = false;
      }
      if ((cols as any).field === 'value') {
        (cols as any).visible = false;
      }
      if ((cols as any).field === 'status') {
        (cols as any).visible = false;
      }
      if ((cols as any).field === 'escrituracao') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'chaveId') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'numeroDoc') {
        (cols as any).width = 60;
      }
      if ((cols as any).field === 'modelo') {
        (cols as any).width = 50;
      }
      if ((cols as any).field === 'dataEmissao') {
        (cols as any).width = 80;
        (cols as any).type = 'date';
        (cols as any).format = 'yMd';
      }
      if ((cols as any).field === 'valorTotalFormatado') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'emitente.cnpj') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'emitente.nome') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'destinatario.cpf') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'destinatario.nome') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'natOperacao') {
        (cols as any).width = 120;
      }
    }

    this.grid.pdfExport(header);
    this.duplicatesGrid = this.duplicatesGridAux;
    setTimeout(() => {

      this.autoFillTable();
    }, 2000);
  }

  excelExport() {
    let period: string;
    this.duplicatesGridAux = this.duplicatesGrid;
    this.duplicatesGrid = null;
    if (this.activeLink !== 'Personalizado') {
      period = this.activeMonth + ' - ' + this.year;
    } else {
      period = moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY');
    }
    const exportProperties: ExcelExportProperties = {
      fileName: 'documentosFiscais' + period + '.xlsx',
      hierarchyExportMode: 'None',
    };

    this.grid2.excelExport(exportProperties);
    this.duplicatesGrid = this.duplicatesGridAux;
    setTimeout(() => {
      this.autoFillTable();
    }, 2000);
  }

  autoFillTable() {
    for (const cols of this.grid.columns) {
      if ((cols as any).field === 'doc.participant.name') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'pagamentoPlanejado.lenght') {
        (cols as any).width = 60;
      }
      if ((cols as any).field === 'escrituracao') {
        (cols as any).width = 150;
      }
      if ((cols as any).field === 'chaveId') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'checkbox') {
        (cols as any).visible = 80;
      }
      if ((cols as any).field === 'arquivo') {
        (cols as any).visible = 100;
      }
      if ((cols as any).field === 'numeroDoc') {
        (cols as any).width = 130;
      }
      if ((cols as any).field === 'modelo') {
        (cols as any).width = 110;
      }
      if ((cols as any).field === 'dataEmissao') {
        (cols as any).width = 150;
        (cols as any).type = 'date';
        (cols as any).format = 'yMd';
      }
      if ((cols as any).field === 'valorTotalFormatado') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'emitente.cnpj') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'emitente.nome') {
        (cols as any).width = 180;
      }
      if ((cols as any).field === 'destinatario.cpf') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'destinatario.nome') {
        (cols as any).width = 180;
      }
      if ((cols as any).field === 'natOperacao') {
        (cols as any).width = 150;
      }
    }

    this.grid.refreshColumns();
  }

  autoFill() {
    this.grid.autoFitColumns(['checkbox', 'arquivo',
      'chaveId', 'numeroDoc', 'escrituracao', 'modelo',
      'dataEmissao', 'valorTotalFormatado', 'emitente.cnpj', 'emitente.nome', 'destinatario.nome', 'destinatario.cpf', 'natOperacao']);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Chave copiada!', 'success');
  }

  setFirstPageTableData(event) {
    this.dfeSelected = [];
    if (event.rows !== undefined) {
      event.rows.forEach(row => {
        this.dfeSelected.push(row.data);
      });
    }
  }

  selectAllToggle() {
    if (!this.checkIfIsAllSelected()) {
      this.dfeSelected.forEach(row => {
        if (this.selectedDocumentsBookkeeping.findIndex(value => value.id === row.id) >= 0) {
          this.selectedDocumentsBookkeeping.splice(this.selectedDocumentsBookkeeping.findIndex(value => value.id === row.id), 1);
        }
        this.selectDocumentForBookkeeping(row);
      });
    } else {
      this.dfeSelected.forEach(row => {
        if (this.selectedDocumentsBookkeeping.findIndex(value => value.id === row.id) >= 0) {
          this.selectedDocumentsBookkeeping.splice(this.selectedDocumentsBookkeeping.findIndex(value => value.id === row.id), 1);
        }
      });
    }
  }

  checkIfIsAllSelected(): boolean {
    for (let i = 0; i < this.dfeSelected.length; i++) {
      if (this.selectedDocumentsBookkeeping.findIndex(value => value.id === this.dfeSelected[i].id) < 0) {
        return false;
      } else {
        if (i === (this.dfeSelected.length - 1)) {
          return true;
        }
      }
    }
  }

  openAccessKeysModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
        this.accessKeyModalService.open(AccessKeyModalComponent, modalOptions);
    } catch (error) {
        console.error('', error);
    }
  }

  openEventsModal(data) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
      const sentData = data.events.sort((a, b) => moment(a.date).diff(moment(b.date))).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      const modalRef = this.eventsModalService.open(EventsModalComponent, modalOptions);
      modalRef.componentInstance.dataSource = sentData;
    } catch (error) {
      console.error('', error);
    }
  }

}

function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1;
  const years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}
