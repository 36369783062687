import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {AccountProvider} from "../../../../../providers/account/account";
import {HttpErrorResponse} from "@angular/common/http";
import {AccountPlanDTOResponse} from "../../../../../model/dto/AccountPlanDTOResponse";
import {AccountType} from "../../../../../model/enum/AccountType";
import {AccountComposite} from "../../../../../model/dto/AccountComponent/AccountComposite";
import {DeletarCategoriaComponent} from "../deletar-categoria/deletar-categoria.component";
import {MatDialog} from "@angular/material/dialog";
import {AccountLeaf} from "../../../../../model/dto/AccountComponent/AccountLeaf";
import {LivroCaixa} from "../../../../../util/livroCaixa";
import {ReleaseType} from "../../../../../model/enum/ReleaseType";
import { HistoricDTOResponse } from 'src/model/dto/HistoricDTOResponse';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { HistoricProvider } from 'src/providers/bookkeeping/historic/historic';
import { ParticipantProvider } from 'src/providers/participant/participant';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { CadastroPessoasComponent } from '../../participantes/cadastro-pessoas/cadastro-pessoas.component';
import { ConvertObjectPipe } from 'src/util/pipes/ConvertObjectPipe';
import { ParticipantPageable } from 'src/model/dto/bookkeping/ParticipantPageable';
import { ParticipantDTOResponse } from 'src/model/dto/ParticipantDTOResponse';
import { CadastroHistoricoComponent } from '../../historicos/cadastro-historico/cadastro-historico.component';
import { ExportProvider } from 'src/providers/export/export';
import Swal from 'sweetalert2';
import { CadastroExportacoesComponent } from '../../exportacoes/cadastro-exportacoes/cadastro-exportacoes.component';

export class Nodes {
  name: string;
  id: number;
  customId: number;
  children: Nodes[];
  featureType: string;
  releaseType: number;
  participateRuralActivity: boolean;
}

@Component({
  selector: 'app-cadastro-categoria',
  templateUrl: './cadastro-plano-de-contas.component.html',
  styleUrls: ['./cadastro-plano-de-contas.component.scss']
})


export class CadastroPlanoDeContasComponent implements OnInit {

  @Input() public node;
  @Input() public editar: boolean;
  @Input() public root: boolean;
  @Input() public descriptions: any[];
  @Input() public customIds: any[];
  @ViewChild('participantInput', {static: false}) participantInput: ElementRef<HTMLInputElement>;

  planoContasForm: FormGroup;
  enviando: boolean = false;
  enviado: boolean = false;
  removable: boolean = true;
  nodePost: number;
  protected _onDestroy = new Subject<void>();
  public filter_historic: ReplaySubject<HistoricDTOResponse[]> = new ReplaySubject<HistoricDTOResponse[]>(1);
  public historicos: HistoricDTOResponse[];
  public historicFilterCtrl: FormControl = new FormControl();

  public selectedHistoric;
  public participants: any = [];
  public participant = new FormControl();
  public participantFilterCtrl: FormControl = new FormControl();
  public participantPage: number = 0;
  public participantes: ParticipantDTOResponse[] = [];
  public filter_participant: ReplaySubject<ParticipantDTOResponse[]> = new ReplaySubject<ParticipantDTOResponse[]>(1);
  public participantLoaded: boolean = false;

  public exports: any[] = [];
  public exportMappings: any[] = [];
  public mappingFormArray: FormArray = new FormArray([]);

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public dialog: MatDialog,
              public livroCaixa: LivroCaixa,
              public accountProvider: AccountProvider,
              public snackBar: Snackbar,
              public erroUtil: ErrorUtil,
              public historicProvider: HistoricProvider,
              public participantProvider: ParticipantProvider,
              public modalService: NgbModal,
              public exportProvider: ExportProvider) {
  }

  ngOnInit() {
    this.getHistorics();
    this.getParticipants();
    this.getExports();
    this.selectedHistoric = {};

    if(!this.editar && !this.root && this.node == undefined){
      this.customIds = [];
      this.descriptions = [];
      this.configuracaoForm();
      this.historicos = this.sortHistorics(localStorage.getItem('historics'));
      this.initializeHistoricSearch();
      this.accountProvider.getAllAccounts().then((planoDeContas: any) => {

        this.root = true;
        this.nodePost = planoDeContas.id;
        this.accountToTree(planoDeContas);
      }).catch((result: HttpErrorResponse) => {
        console.error(result);
      });

    }else{
      this.configuracaoForm();
      this.checkExist();
  }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getHistorics(){
    this.historicProvider.getAllHistorics().then((historicos : HistoricDTOResponse[]) => {
      this.historicos = historicos;
      this.initializeHistoricSearch();
    });
  }

  getParticipants(){
    this.getFirstPage();
  }

  initializeHistoricSearch() {
  this.filter_historic.next(this.historicos.slice());
  this.historicFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterHistoric();
    });
  }

  selectParticipant(event: any){
    let exist = false;
    let eventInsert = event.value;
    this.participants.forEach((participant, index: number) => {
      if (participant.id === eventInsert.id) {
        exist = true;
        this.snackBar.open('Participante já selecionado!', 'atencao');
      }
      if (!exist && this.participants.length === index + 1) {
        this.participants.push(eventInsert);
        this.participantFilterCtrl.setValue(null);
      }
    });
    if (this.participants.length === 0) {
      this.participants.push(eventInsert);
      this.participantFilterCtrl.setValue(null);
    }
  }

  removeParticipant(indx): void {
    this.participants.splice(indx, 1);
  }

  newParticipant() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPessoasComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result != 'cancelou') {
        this.participants.push(result);
        this.getFirstPage();
      }
    }, () => {
    });
  }

  newHistoric() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroHistoricoComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.getHistorics();
      }
    }, () => {
    });
  }

  getFirstPage() {
    let pipe = new ConvertObjectPipe();
    this.participantPage = 0;
    this.participantFilterCtrl.setValue('');
    this.participantProvider.getParticipantsByPage(this.participantFilterCtrl.value, this.participantPage).then((participantes: ParticipantPageable) => {
      this.participantes = pipe.transform(participantes.content, 'participantes');
      this.participantPage++;
      this.filterParticipants();
    });
  }

  editParticipant(participant){
    participant.name = participant.name.split(' -')[0];
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPessoasComponent, modalOptions);
    modalRef.componentInstance.participantes = this.participantes;
    modalRef.componentInstance.participanteExistente = participant;
    modalRef.result.then((result: any) => {
      if (result){
        this.participants.forEach((participant) => {
          if(participant.id == result.id){
            this.removeParticipant(this.participants.indexOf(participant));
          }
          this.participants.push(result);
        });
        this.participants = this.participants;
        this.planoContasForm.controls.participant.setValue(null);
        this.getFirstPage();
      }
    }, () => {
    });
  }

  editHistoric(historic){
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroHistoricoComponent, modalOptions);
    modalRef.componentInstance.existingHistoric = historic;
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.getHistorics();
        this.planoContasForm.controls.historic.setValue(result);
      }
    }, () => {
    });
  }

  getNextBatch(isScroll: boolean) {
    let search = '';
    if(this.participantFilterCtrl.value != undefined) {
      search = this.participantFilterCtrl.value;
    }

    let pipe = new ConvertObjectPipe();
    if(isScroll) {
      this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
        this.participantLoaded = participantes.last;
        this.participantPage++;

        this.participantes = this.participantes.concat(
          pipe.transform(participantes.content, 'participantes')
        );
        this.filterParticipants();
      });
    } else {
      if(search.length >= 3 || search.length == 0) {
        this.participantPage = 0;

        this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
          this.participantLoaded = participantes.last;
          this.participantPage++;
          this.participantes = pipe.transform(participantes.content, 'participantes');
          this.filterParticipants();
        });
      }
    }
  }

  protected filterParticipants() {
    this.filter_participant.next(this.participantes.slice());
  }

  protected filterHistoric() {
    if (!this.historicos) {
      return;
    }
    // get the search keyword
    let search = this.historicFilterCtrl.value;
    if (!search) {
      this.filter_historic.next(this.historicos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the properties
    this.filter_historic.next(
      this.historicos.filter(historic => historic.description.toLowerCase().indexOf(search) > -1)
    );
  }

  sortHistorics(historicos) {
    return historicos.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  accountToTree(accountComponent) {
    if (accountComponent.description == 'root') {
      this.printToTree(accountComponent.accountComponents);
      this.checkExist();
    }
  }

  printToTree(accountComponent): any {
    accountComponent.forEach((account: any) => {
      this.customIds.push(account.customId);
      this.descriptions.push(account.description);
      if (account.accountComponents != undefined || account.accountComponents != null) {
        this.printToTree(account.accountComponents);
       }
    });
  }

  configuracaoForm() {
    this.planoContasForm = this.fb.group({
      id: [''],
      customId: ['', Validators.compose([Validators.required, EqualsValidator(this.customIds)])],
      description: ['', Validators.compose([Validators.required, EqualsValidator(this.descriptions)])],
      featureType: ['', Validators.required],
      type: ['', Validators.required],
      releaseType: [''],
      participateRuralActivity: [''],
      accountComponents: [''],
      historico: [''],
      participant: [''],
    });
  }

  checkExist() {
    if (this.node != undefined) {
      if(this.root) {
        this.nodePost = this.node;
      } else {
        this.nodePost = this.node.id;
        this.planoContasForm.controls.featureType.setValue(AccountType[this.node.featureType].toString());
        this.planoContasForm.controls.featureType.disable();
        if (this.livroCaixa.getTipo() == 0 && AccountType[this.node.featureType].toString() == '0') {
          this.planoContasForm.controls.releaseType.setValue('1');
        } else if (this.livroCaixa.getTipo() == 0 && AccountType[this.node.featureType].toString() == '1') {
          this.planoContasForm.controls.releaseType.setValue('2');
        }
      }
    }

    if (this.editar) {
      //******retira o id/descrição vinculados ao nó a ser editado

      this.customIds.forEach((nodeFind, index: number) => {
        if (this.node.customId == nodeFind) {
          this.customIds.splice(index, 1);
        }
      });

      this.descriptions.forEach((nodeFind, index: number) => {
        if (this.node.name == nodeFind) {
          this.descriptions.splice(index, 1);
        }
      });

      //******

      this.planoContasForm.controls.id.disable();
      this.planoContasForm.controls.featureType.disable();
      this.planoContasForm.controls.type.disable();
      this.planoContasForm.controls.id.setValue(this.node.id);
      this.planoContasForm.controls.customId.setValue(this.node.customId);
      this.planoContasForm.controls.description.setValue(this.node.name);
      if (this.node.releaseType == undefined || this.node.releaseType == null) {
        this.planoContasForm.controls.type.setValue("0");
      } else {
        this.planoContasForm.controls.type.setValue("1");
        this.participantProvider.getAccountParticipants(this.node.id).then((participants:any) =>{
          //console.log("PARTICIPANTS", participants);
          if(participants.lenght!=0){
            this.participants = participants;
          }
        })
        if(this.node.historicId){
          this.historicProvider.getHistoricById(this.node.historicId).then((historico : any) => {
            this.filter_historic.forEach((filter_historic) =>{
              filter_historic.forEach((historic) =>{
                if(historic.id == historico.id){
                  this.planoContasForm.controls.historico.setValue(historic);
                }
              });
            })
          });
        }


        // console.log("NODE", this.node);
        // console.log("O LOG MAIS FODA", this.planoContasForm)

        // this.planoContasForm.controls.releaseType.setValidators(Validators.required);
        this.planoContasForm.controls.releaseType.setValue(this.node.releaseType.toString());
        this.planoContasForm.controls.participateRuralActivity.setValidators(Validators.required);
        this.planoContasForm.controls.participateRuralActivity.setValue(this.node.participateRuralActivity);
      }
    }
  }

  setHistoric(value){
    this.planoContasForm.controls.historico.setValue(value);
  }

  setObjectToPut(): AccountComposite {
    let accountPlanEdited = new AccountComposite();
    // console.log("planos de conta form no put", this.planoContasForm)
    accountPlanEdited.companyId = +localStorage.getItem('idEmpresa');
    accountPlanEdited.id = this.planoContasForm.controls.id.value;
    accountPlanEdited.customId = this.planoContasForm.controls.customId.value;
    accountPlanEdited.description = this.planoContasForm.controls.description.value.toUpperCase();
    accountPlanEdited.historicId =
      this.planoContasForm.controls.historico.value ? this.planoContasForm.controls.historico.value['id'] : null;
    accountPlanEdited.participantIds = [];
    this.node['@type'] == "Leaf"? accountPlanEdited['type'] = "Leaf":'';
    this.participants.forEach((participant) =>{
      accountPlanEdited.participantIds.push(participant.id);
    })
    // console.log("accountPlanEdited", accountPlanEdited)
    return accountPlanEdited;
  }

  setObjectEdited(planoDeContasEditado) {
    this.node.name = planoDeContasEditado.description.toUpperCase();
    this.node.customId = planoDeContasEditado.customId;
  }

  setObjectToPost(planoDeContasFormObj) {
    let objetPlanToPost;
    let type;
    if (planoDeContasFormObj.type == '1') {
      //categoria analitica
      objetPlanToPost = new AccountLeaf();
      objetPlanToPost.releaseType = planoDeContasFormObj.releaseType;
      objetPlanToPost.description = planoDeContasFormObj.description.toUpperCase();
      objetPlanToPost.historicId = planoDeContasFormObj.historico.id;
      objetPlanToPost.participantIds = [];
      this.participants.forEach((participant) =>{
        objetPlanToPost.participantIds.push(participant.id);
      })
      objetPlanToPost.companyId = +localStorage.getItem('idEmpresa');
      objetPlanToPost.customId = planoDeContasFormObj.customId;
      objetPlanToPost.featureType = AccountType[planoDeContasFormObj.featureType];
      objetPlanToPost.participateRuralActivity = planoDeContasFormObj.releaseType != '0';
      type = 'Leaf';
    } else {
      //categoria sintética
      objetPlanToPost = new AccountComposite();
      objetPlanToPost.description = planoDeContasFormObj.description.toUpperCase();
      objetPlanToPost.companyId = +localStorage.getItem('idEmpresa');
      objetPlanToPost.customId = planoDeContasFormObj.customId.toString();
      objetPlanToPost.featureType = AccountType[planoDeContasFormObj.featureType];
      objetPlanToPost.accountComponents = [];
      type = 'Composite';
    }
    return this.getObject(type, objetPlanToPost);
  }

  getObject(type: string, rawData?: any) {
    let object = {
      '@type': type,
    };
    if (rawData) {
      object = Object.assign({}, object, rawData);
    }
    return this.toJson(object);
  }

  toJson(object) {
    return (object);
  }

  postData() {
    this.enviado = true;
    if (this.planoContasForm.valid) {
      this.enviando = true;
      if (!this.editar) {
        this.accountProvider.postAccount(this.nodePost, this.setObjectToPost(this.planoContasForm.getRawValue())).then((planoDeContas: AccountPlanDTOResponse) => {
          this.enviando = false;
          if(this.mappingFormArray.controls.length > 0) {
            this.saveMappings(true);
          } else {
            this.snackBar.openLong(planoDeContas.description + " inserido com sucesso!", 'success');
            this.activeModal.close(planoDeContas);  
          }
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.openLong('Não foi possível inserir! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'account'), 'erro');
        });
      } else {
        this.accountProvider.putAccount(this.setObjectToPut()).then((planoDeContas: any) => {
          this.snackBar.openLong(planoDeContas.description + " editado com sucesso!", 'success');
          this.setObjectEdited(planoDeContas);
          this.activeModal.close((planoDeContas));
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.openLong('Não foi possível editar! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'account'), 'erro');
        });
      }
      // this.enviando = false;
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  deletePlan() {
    const dialogRef = this.dialog.open(DeletarCategoriaComponent, {
      width: '350px',
      data: this.node
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != 'cancelou'  && result != undefined) {
        this.activeModal.close('excluido');
      }
    });
  }

  checkReleaseTypeValidators() {
    if (this.planoContasForm.controls.type.value == '1') {
      this.planoContasForm.controls.releaseType.enable();
      this.planoContasForm.controls.releaseType.setValidators(Validators.required);
      if (this.planoContasForm.controls.featureType.value == '0') {
        this.planoContasForm.controls.releaseType.setValue('1');
      } else {
        this.planoContasForm.controls.releaseType.setValue('2');
      }
    } else {
      this.planoContasForm.controls.releaseType.disable();
    }
  }

  addMappingFormGroup() {
    this.mappingFormArray.push(this.fb.group({
      id: [null],
      exportId: [null, Validators.required],
      localModuleId: ["ACCOUNT_PLAN"],
      foreignCode: [null, Validators.required],
    }))
  }

  getExports() {
    Promise.all([this.exportProvider.getExports(), this.exportProvider.getExportMappingByAccountId(this.node.id)]).then(([exports, exportMappings]: any) => {
      this.exports = exports;
      this.exportMappings = exportMappings;
      this.exportMappings.length > 0 ? this.setExportMappingsToForm() : '';
    }).catch((result: HttpErrorResponse) => {
      this.snackBar.openLong('Erro ao buscar exportações ' + result.error.error_description, 'erro');
    });
  }

  createOrEditExport(exportacao?: any) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroExportacoesComponent, modalOptions);
    if (exportacao) {
      modalRef.componentInstance.exportacaoExistente = exportacao;
      modalRef.componentInstance.editar = true;
    }
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.getExports();
      }
    }, () => {
    });
  }

  setExportMappingsToForm() {
    this.mappingFormArray.clear();
    this.exportMappings.forEach((mapping) => {
      this.mappingFormArray.push(this.fb.group({
        id: [mapping.id],
        exportId: [mapping.exportId, Validators.required],
        localModuleId: ["ACCOUNT_PLAN"],
        foreignCode: [mapping.foreignCode, Validators.required],
      }));
    });
  }

  checkUniqueMappings() {
    let exportIds = this.mappingFormArray.controls.map((control) => control.value.exportId);
    return exportIds.some((exportId, index) => exportIds.indexOf(exportId) !== index);
  }

  saveMappings(close) {
    if (!this.mappingFormArray.valid) {
      this.snackBar.openLong('Preencha todos os campos obrigatórios!', 'erro');
      return;
    }
    if(this.checkUniqueMappings()){
      this.snackBar.openLong('Não é possível repetir exportações!', 'erro');
      return;
    }
    this.enviando = true;
    let exportMappingArray = this.mappingFormArray.controls.map((control) => {
      let exportMappingObj = control.value;
      exportMappingObj['localId'] = this.planoContasForm.controls.id.value;
      return exportMappingObj;
    });
    this.exportProvider.postExportMapping(exportMappingArray).then(() => {
      this.enviando = false;
      this.snackBar.openLong('Mapeamentos salvos com sucesso!', 'success');
      close ? this.activeModal.close() : this.getExports();
    }).catch((result: HttpErrorResponse) => {
      this.enviando = false;
      this.snackBar.openLong('Erro ao salvar mapeamento! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'export'), 'erro');
    });
  }

  deleteExportMapping(i) {
    if (this.mappingFormArray.controls[i].value.id) {
      Swal.fire({
        title: 'Excluir mapeamento?',
        text: 'O mapeamento será excluído permanentemente!',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.exportProvider.deleteExportMapping(this.mappingFormArray.controls[i].value.id).then((result) => {
            this.snackBar.openLong('Mapeamento excluído com sucesso!', 'success');
            this.mappingFormArray.removeAt(i);
          }).catch((result: HttpErrorResponse) => {
            this.snackBar.openLong('Erro ao excluir mapeamento! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'export'), 'erro');
          });
        }
      });
    } else {
      this.mappingFormArray.removeAt(i);
    }
  }
}

function EqualsValidator(listValues: any[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (listValues.find(e => e == control.value)) {
      return {typeValue: true};
    } else {
      // return {typeValue: false};
    }
    return null;
  };
}

