import {AccountPlanComponents} from "./AccountPlanComponents";

export class AccountPlanDTOResponse {
  id: number;
  customId: number;
  description: string;
  featureType: string;
  accountComponents: AccountPlanComponents[];
  participateRuralActivity: boolean;
  releaseType: number;
}
