import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StockProvider } from 'src/providers/stock/stock';
import { DateUtil } from 'src/util/dateUtil';
import { GridComponent, PdfExportProperties } from '@syncfusion/ej2-angular-grids';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { Snackbar } from "../../../../util/snackbar";
import { MemberProvider } from "../../../../providers/company/member/member";
import { CadastroSocioComponent } from "../../minhasPropriedades/cadastro-socio/cadastro-socio.component";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {ReportsUtil} from "../../../../util/Reports";
import {ModalExportComponent} from "../modal-export/modal-export.component";

@Component({
  selector: 'app-relatorio-estoque',
  templateUrl: './relatorio-estoque.component.html',
  styleUrls: ['./relatorio-estoque.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class RelatorioEstoqueComponent implements OnInit {

  @ViewChild('grid', { static: false }) grid: GridComponent;

  protected _onDestroy = new Subject<void>();

  public carregando: boolean = false;
  public relatorioForm: FormGroup;

  public dataSource: any[] = [];
  //Product
  public memberFilterCtrl: FormControl = new FormControl();
  public filter_member: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public members: any[] = [];

  public toolbarOptions: any[];

  public dateRange: ConsultDate = new ConsultDate();
  public dateStart: string;
  public dateEnd: string;

  public erro: boolean = false;
  public periodo = 'Personalizado'
  public contador: any = 0;
  public customAttributes: any;
  public ano
  public years
  constructor(
    public memberProvider: MemberProvider,
    public stockProvider: StockProvider,
    private _adapter: DateAdapter<any>,
    public snackBar: Snackbar,
    public fb: FormBuilder,
    public dateUtil: DateUtil,
    public reportsUtil: ReportsUtil,
    public modalService: NgbModal,
  ) { }

  ngOnInit() {
    this._adapter.setLocale('pt-BR');

    this.defaultConfiguration();
    this.initializeFormConfiguration();
    this.getRelatorio();
    this.initializeData();
    this.years = getYears(+moment().format('YYYY') - 20);
this.ano = +moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).format('YYYY');
this.relatorioForm.controls.ano.setValue(this.ano)
    this.customAttributes = {class: 'customcss'};

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  chosenYearHandler(normalizedYear: any) {
    this.ano = normalizedYear;
  }
  defaultConfiguration() {
    // tslint:disable-next-line: prefer-const
    let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    this.dateRange.startDate = moment.tz(workDate).locale('pt-BR').startOf('month').add(1, 'day').format();
    this.dateRange.endDate = moment.tz(workDate).locale('pt-BR').endOf('month').format();

    this.dateStart = moment(this.dateRange.startDate).format('YYYY-MM-DD');
    this.dateEnd = moment(this.dateRange.endDate).format('YYYY-MM-DD');
  }

  initializeFormConfiguration() {
    this.toolbarOptions = [
      {text: 'Exportar', tooltipText: 'Exportar', prefixIcon: 'e-pdfexport', id: 'export'},
    ];

    // this.setPeriod(true);

    this.relatorioForm = this.fb.group({
      ano: [ '', Validators.required],
      startDate: [this.dateRange.startDate, Validators.required],
      endDate: [this.dateRange.endDate, Validators.required],
      memberCpfCnpj: [''],
    });
  }

  initializeData() {
    this.getMembers();
  }

  formatarValor(valor) {
    return new Intl.NumberFormat('pt-BR').format(valor);
  }

  toolbarClick(args: any): void {
    if (args.item.id === 'export') {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : '',
      };
      const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
      modalRef.componentInstance.texto = "os lançamentos de estoque";
      modalRef.componentInstance.relatorio = 'estoque';
      modalRef.result.then((result: any) => {
        if (result.type === 'pdf') {
          this.exportPDF(result.img);
        } else if (result.type === 'excel') {
          this.excelExport();
        }
      });
    }
  }

  exportPDF(img) {
    let period = this.ano;
    if (this.periodo == 'Personalizado') {
      let str = moment(this.relatorioForm.get('startDate').value).format('DD/MM/YYYY');
      let str2 = moment(this.relatorioForm.get('endDate').value).format('DD/MM/YYYY');

      period = str + ' - ' + str2;
    }

    const date = moment().format('DD/MM/YY HH:mm').toString();

    let members = 'Todos os produtores';

    if (this.relatorioForm.get('memberCpfCnpj').value != "") {
      members = this.relatorioForm.get('memberCpfCnpj').value.name + ' (' + this.relatorioForm.get('memberCpfCnpj').value.cpfCNPJ + ')';
    }

    let headers = [
      {header: 'Código', dataKey: 'productCode'},
      {header: 'Produto', dataKey: 'productDescription'},
      {header: 'Anterior', dataKey: 'previousAmount'},
      {header: 'Estoque Inicial', dataKey: 'initialStock'},
      {header: 'Nascimento', dataKey: 'birth'},
      {header: 'Compra', dataKey: 'purchase'},
      {header: 'Consumo/Perda', dataKey: 'consumptionLosses'},
      {header: 'Venda', dataKey: 'sales'},
      {header: 'Total de Entrada', dataKey: 'entryAmount'},
      {header: 'Total de Saída', dataKey: 'outputAmount'},
      {header: 'Saldo', dataKey: 'finalBalance'},
    ];

    let colunas = [
      {dataKey: 'previousAmount', halign: 'center'},
      {dataKey: 'initialStock',   halign: 'center'},
      {dataKey: 'birth',  halign: 'center'},
      {dataKey: 'purchase',  halign: 'center'},
      {dataKey: 'consumptionLosses',  halign: 'center'},
      {dataKey: 'sales',  halign: 'center'},
      {dataKey: 'entryAmount',  halign: 'center'},
      {dataKey: 'outputAmount',  halign: 'center'},
      {dataKey: 'finalBalance',  halign: 'center'},
    ];

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Estoque Geral - ${period}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Estoque geral`,
        data: date,
        periodo: period,
        parametros: {
          "Produtor": members,
        },
        image: img,
        alturaExtra: img ? 1 : undefined,
      },
      tabela: {
        colunas: headers,
        dados: this.dataSource,
        estiloColunas: colunas,
        diminuir: true,
      },
    });
  }

  excelQueryCellInfo(args: any): void {
    // Formata a data
    // if (args.column.field == 'postDate') {
    //   args.value = moment(args.data.postDate).format('DD/MM/YYYY');
    // }
  }

  excelExport() {
    let period = this.ano;
    if (this.periodo == 'Personalizado') {
      let str = moment(this.relatorioForm.get('startDate').value).format('DD/MM/YYYY');
      let str2 = moment(this.relatorioForm.get('endDate').value).format('DD/MM/YYYY');

      period = str + ' - ' + str2;
    }
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let exportProperties = {
      fileName: `Relatorio de Estoque - ${date}.xlsx`,
    };
    this.grid.excelExport(exportProperties);
    this.grid.refresh();
  }

  async getRelatorio() {
    this.carregando = true;

    let params = {
      startDate:  this.dateStart,
      endDate: this.dateEnd,
      memberCpfCnpj: this.relatorioForm.get('memberCpfCnpj').value.cpfCNPJ
    }
    //console.log("params:", params);

    if (!params.startDate || !params.endDate) {
      this.snackBar.open('Informe o período', 'erro');
      this.carregando = false;
      this.erro = true;
      return;
    } else if (params.startDate > params.endDate) {
      this.snackBar.open('Data inicial não pode ser maior que a data final', 'erro');
      this.carregando = false;
      this.erro = true;
      return;
    }

    this.stockProvider.getRelatorioEstoque(params).then((result: any) => {
      this.dataSource = result;
      //console.log("this.dataSource:", this.dataSource);
      this.carregando = false;
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;

    });
  }

  getMembers() {
    this.memberProvider.getAllMembers().then((members: any[]) => {
      this.members = members;
      //console.log("this.memberProvider.getAllMembers ~ this.members:", this.members)
      this.initializeSearchMembers();
      this.carregando = false;
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  newMember() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroSocioComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result) {
        this.getMembers();
      }
    }, () => {
    });
  }

  initializeSearchMembers() {
    this.filter_member.next(this.members.slice());
    this.memberFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterMembers();
    });
  }

  filterMembers() {
    if (!this.members) {
      return;
    }
    let search = this.memberFilterCtrl.value;
    if (!search) {
      this.filter_member.next(this.members.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_member.next(
      this.members.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  changePeriod(filtro) {
    if(filtro == 'Personalizado'){
      //console.log("teste")
      this.dateStart = moment.tz(this.relatorioForm.get('startDate').value , 'America/Sao_Paulo').format('YYYY-MM-DD');
      this.dateEnd = moment.tz(this.relatorioForm.get('endDate').value, 'America/Sao_Paulo').format('YYYY-MM-DD');

    }else{
      this.dateStart = this.relatorioForm.get('ano').value + "-01-01";
      this.dateEnd = this.relatorioForm.get('ano').value + "-12-31";

    }


    if (this.dateStart && this.dateEnd && this.dateStart <= this.dateEnd) {
      this.erro = false;
      this.getRelatorio();
    } else {
      this.erro = true;
      this.carregando = false;
      this.snackBar.open('Data inicial deve ser menor que a data final', 'erro');
    }

  }
}
function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1, years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}
class ConsultDate {
  endDate: any;
  startDate: any;
}
